.navbar{
  margin:0;
  padding:0;
}
ul.nav-menu {
  position:relative;
  display:grid;
  grid-template-rows: 40px;
  grid-template-columns: 30px auto;
  justify-items: center;
  align-items: center;
  min-width:300px;
  width: 100%;
  margin:0;
  padding: 0;
}
ul.nav-menu .nav-brand {
  grid-column: 1;
  justify-self: right;
  background-image: url("../../../../public/img/logo-color-big.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: -5px 0;
  width: 20px;
  height: 40px;
  background-color: transparent;
  margin: 0;
}
ul.nav-menu .menu-always-visible{
  grid-column: 2;
  display:flex;
  flex-shrink: 0;
  justify-content:right;
  align-items: center;
  padding:0 10px;
  width:95%;
}
.menu-always-visible .menu-item{
  padding:0 5px;
}
.menu-always-visible .menu-item.language div.flags-container {
  display:flex;
  flex-wrap: wrap;
}
.menu-always-visible .menu-item.language{
  width:40px;
}
.menu-item.language div.flags-container .btn {
  padding: 2px 0px;
  width:38px;
}
li.menu-item.user div#user-icon-badge{
  min-width:50px;
}
li.menu-item.user .badge.rounded-pill{
  margin-top: -25px;
}
.menu-always-visible .menu-item.user .user-name {
  display: none;
}
ul.nav-menu .menu-collapsable{
  position:relative;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  font-size: 1.5rem;  
  width:210px;
  position: absolute;
  top: 40px;
  left: 15px;
}
.menu-collapsable .close-button:hover {
  color: black;
  font-weight: 500;
}
.menu-collapsable .close-button {
  display: inline-block;
  font-size: 2.4rem;
  position: absolute;
  top: 3px;
  right: 15px;
  z-index: 100;
  cursor: pointer;
}

.menu-collapsable .menu-item{
  padding: 5px;
  text-align: left;
  font-size:13px;
}
.menu-collapsable .menu-item a{
  padding:0 !important;
}
li#loginHorseAround div#loginButtonHorseAround,
li.menu-item.user .user-name {
  font-size: 12px !important;
}
#horseAround_navbar_timer{
  color:rgb(255, 102, 0);
  font-weight: bolder;
  padding-top: 2px;;
  padding-left: 5px;
  width:1px;/* overflown when timer in action */
}
@media (min-width: 400px){  
  li.menu-item.user div#user-icon-badge {
    width: 90px;
  }
  li.menu-item.user .badge.rounded-pill::after {
    content: "ction" !important;
  }
  .menu-always-visible .menu-item.user .user-name{
    display:block;
    max-width: 80px;
    overflow-x: hidden;
    text-overflow: ellipsis;
  }
}

@media (min-width: 550px){
  ul.nav-menu {
    grid-template-columns: 160px auto;
    padding: 0 10px;
  }
  ul.nav-menu .nav-brand {
    justify-self: left;
    background-position-y: -5px;
    width: 150px;
  }
}

@media (min-width: 768px) {
  ul.nav-menu {
    grid-template-columns: 180px auto;
    padding: 0 20px;
  }
  ul.nav-menu .menu-collapsable{
    left:20px;
  }
  .menu-always-visible .menu-item.language {
    width: 100px;
  }
  .menu-always-visible .menu-item.language div.flags-container {
    flex-wrap: nowrap;
  }
  /* .menu-item.language div.flags-container .btn {
    padding-right: 4px;
  } */
}

@media (min-width: 1000px){
  ul.nav-menu {
    grid-template-columns: 150px auto 380px;
  }
  ul.nav-menu .menu-collapsable{
    position:static;
    width:80%;
    grid-row: 1;
    grid-column: 2;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  .menu-collapsable .close-button {
    display: none;
  }
  .menu-collapsable .menu-item {
    text-align: center;
  }
  ul.nav-menu .menu-always-visible{
    grid-column: 3;
  }
  
}
@media (min-width: 1100px){
ul.nav-menu {
    grid-template-columns: 180px 450px 420px;
  }
  ul.nav-menu .nav-brand {
    width: 170px;
  }
  .menu-always-visible .menu-item.user .user-name {
    max-width:100px;
  }
}
@media (min-width: 1300px){
  ul.nav-menu {
    grid-template-columns: 200px 600px 400px; 
    margin:0 3%;
  }
  .menu-always-visible .menu-item {
    padding: 0 10px;
  }
  ul.nav-menu .nav-brand {
    justify-self: center;
    background-position-x: -10px;
    background-position-y: -10px;
    width: 200px;
  }
}
@media (min-width: 1400px) {
  ul.nav-menu {    
    grid-template-columns: 200px 550px 500px;
    margin: 0 8%;
  }
  ul.nav-menu .menu-collapsable{
    width:90%;
  }
  .menu-collapsable .menu-item {
    font-size: 15px;
  }

  li#loginHorseAround div#loginButtonHorseAround,
  li.menu-item.user .user-name {
    font-size: 14px !important;
  }
  .menu-always-visible .menu-item.user .user-name {
    max-width: 150px;
  }
}
@media (min-width: 2000px){
  ul.nav-menu {
    grid-template-columns: 200px 750px 800px;
  }
  .menu-always-visible .menu-item.user .user-name {
    max-width: none;
  }
}

ul.nav-menu .menu-collapsable>li>a:after {
  content: "";
  height: 3px;
  left: 0;
  bottom: -3px;
  width: 0px;
  position: absolute;
  transition: all 0.3s ease-in-out;
  background-color: #7AA095;
}
ul.nav-menu .menu-collapsable>li>a:hover {
  color: #7AA095 !important;
  /* border-bottom: 1px solid rgba(255, 255, 255, 0.3); */
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=$value)";
  filter: alpha(opacity=1);
  -moz-opacity: 1;
  -khtml-opacity: 1;
  opacity: 1;
  font-weight: 600;
}
ul.nav-menu .menu-collapsable>li>a:hover:after {
  width: 0%;
}

@media (min-width: 1000px) {
  ul.nav-menu .menu-collapsable>li>a:hover:after
  {
    width: 100%;
  }
}

#destinations-navbar-popper,#activities-navbar-popper{
  padding-top:20px;
  padding-left: 20px;
  min-width:300px;
  overflow-y:auto;
  height:350px;
  border-radius: 5px;
  z-index: 100;
}
@media (min-width: 1000px) {
  #destinations-navbar-popper {
    overflow: hidden;
    min-width: 600px;
    height:auto;
  }
  #activities-navbar-popper{
    overflow: hidden;
    min-width: 550px;
    height: auto;
  }
}
@media (min-width: 1400px) {
  #destinations-navbar-popper {
    min-width: 650px;
  }
  #activities-navbar-popper {
    min-width: 850px;
  }
}