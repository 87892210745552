.flexible-modal {
  position: absolute;
  z-index: 1;
  border: 1px solid #ccc;
  background: #F5F5F5;
}
.flexible-modal-mask {
  position: fixed;
  height: 100%;
  background: transparent;
  top:0;
  left:0;
  right:0;
  bottom:0;
}
.flexible-modal-resizer {
  position:absolute;
  right:0;
  bottom:0;
  cursor:se-resize;
}
.flexible-modal-drag-area{
  background: #F5F5F5;
  height: 15px;
  position:absolute;
  right:0;
  top:0;
  cursor:move;
}