/* MEMBER PAGE TABS */
.member-tabs {
  flex-grow: 1;
}
.member-tabs .state {
  position: absolute;
  left: -10000px;
}

.member-tabs .flex-tabs {
  margin-top: 70px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
}
@media screen and (min-width: 768px){
  .member-tabs .flex-tabs{
    margin-top: 75px;
  }
}

.member-tabs .tab-label {
  align-items: center;
  background-color: #262a2e;
  color: rgba(255, 255, 255, 0.4);
  cursor: pointer;
  display: flex;
  flex-grow: 1;
  flex-shrink: 1;
  font-size: 1.7rem;
  font-weight: 500;
  height: 6rem;
  justify-content: center;
  text-transform: capitalize;
  transition: color .3s ease-in-out;
}

.member-tabs .tab-label:hover {
  color: #fff;
}

@media screen and (min-width: 1400px) {
  .flex-tabs .tab-label:first-of-type {
    padding-left: auto;
  }

  .flex-tabs .tab-label:last-of-type {
    padding-right: auto;
  }
}

#tab1:checked~.tabs #tab1-label,
#tab2:checked~.tabs #tab2-label,
#tab3:checked~.tabs #tab3-label,
#tab4:checked~.tabs #tab4-label,
#tab5:checked~.tabs #tab5-label,
#tab6:checked~.tabs #tab6-label,
#tab7:checked~.tabs #tab7-label,
#tab8:checked~.tabs #tab8-label {
  cursor: default;
  color: #fff;
}

.member-tabs .panel {
  background-color: #fff;
  display: block;
  width: 100%;
  height: 70vh;
  /* for the Profile tab, height is set in MemberPage.jsx tab8-panel */
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
  /* close button positioning */
  margin: 10px 10px 30px 10px;

}
.member-tabs .panel#tab3-panel,.member-tabs .panel#tab2-panel,
.member-tabs .panel#tab5-panel{
  overflow-y:hidden;
}
.member-tabs .panel#tab3-panel{
  height: 70vh;
  margin-bottom: 0;
}
.member-tabs .panel#tab5-panel{
  height: 65vh;
  margin-bottom: 0;
}
.member-tabs .panel#tab5-panel .tab-pane#tab-pane-conditions{
  height:500px;
  overflow-y: auto;
  margin-bottom: 0;
}
@media screen and (min-width: 1800px){
  .member-tabs .panel#tab5-panel .tab-pane#tab-pane-conditions{
    height:560px
  }
}

.member-tabs .panel span.close-button{
  color: #7aa095;
  border:1px solid;
  padding:2px;
  border-radius: 50%;
}
.member-tabs .panel .close-button:hover,div.form-comment div.d-flex .close-button:hover,
div.member-form-toolbar-wrapper .close-button:hover {
  font-weight: 600;
  cursor:pointer;
  border:2px solid;
}
div.form-comment div.d-flex .close-button{
  font-size: 3rem;
}
div.member-form-toolbar-wrapper .close-button{
  position:absolute;
  top:15px; 
  left: 3px;
  font-size: 3.5rem;
  z-index: 1000;
}
.member-tabs .panel .close-button {
  display: none;
}
@media screen and (min-width: 445px){
  div.member-form-toolbar-wrapper .close-button{
    top: 16px;
    left:auto;
    right:-30px;
  }
}
@media screen and (max-width: 600px) {
  .flex-tabs {
    flex-direction: column;
  }

  .flex-tabs .tab-label {
    border-radius: 5px;
    width: 93%;
  }

  .member-tabs .panel .close-button {
    display: inline-block;
    font-size: 3rem;
    position: absolute;
    top: -5px;
    right: 20px;
    z-index: 100;
    cursor: pointer;
  }

  #tab1-label {
    order: 1;
  }

  #tab1-panel {
    order: 2;
  }

  #tab2-label {
    order: 3;
  }

  #tab2-panel {
    order: 4;
  }

  #tab3-label {
    order: 5;
  }

  #tab3-panel {
    order: 6;
  }

  #tab4-label {
    order: 7;
  }

  #tab4-panel {
    order: 8;
  }

  #tab5-label {
    order: 9;
  }

  #tab5-panel {
    order: 10;
  }

  #tab6-label {
    order: 11;
  }

  #tab6-panel {
    order: 12;
  }

  #tab7-label {
    order: 13;
  }

  #tab7-panel {
    order: 14;
  }

  #tab8-label {
    order: 15;
  }

  #tab8-panel {
    order: 16;
  }
}
@media screen and (min-width: 601px){
  #tab7-label.pro_admin,#tab8-label.pro_admin {
    width: 25%;
  } 
}
@media screen and (min-width: 900px) {
  div.member-form-toolbar-wrapper .close-button {
    right: 5px;
  }
}
@media screen and (min-width: 930px) {
  div.member-form-toolbar-wrapper .close-button {
    right: 25px;
  }
}
@media screen and (min-width: 1400px) {
  div.member-form-toolbar-wrapper .close-button {
    right: 70px;
  }
}
@media screen and (min-width: 1600px) {
  #tab7-label.pro_admin,
  #tab8-label.pro_admin {
    width:auto;
  }
}

/* MEMBER FORM GENERAL */
.member-form-container {
  position:relative;
  margin: 0 5px;
  padding: 0 15px 5px 0;
  font-size: 1.4rem;
}

.member-form-toolbar {
  background-color: #F3F3F3;
  border: 1px solid;
  border-color: green;
  border-radius: 10px;
  padding: 5px 5px;
  margin-top: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 200;
}

.member-form-toolbar label{
  color:blue;
  font-weight: 700;
  margin:0 15px;
}

.member-form-container .separator {
  grid-column: 1/-1;
  color: #7aa095;
  font-weight: 600;
  font-style: italic;
}

.member-form-container .separator-first {
  margin: 10px 0 0 0;
}

.member-form-container hr {
  grid-column: 1/-1;
  height: 1px;
  margin: 0;
  background-color: black;
}

.member-label {
  white-space: pre-wrap;
  text-align: left;
  line-height: 1;
  padding: 5px 5px;
  font-weight: 550;
  margin-bottom: 0;
  color: green;
}

.member-control-container {
  position: relative;
  /* i, aft-label, info positioning */
  margin: 0;
  height: fit-content;
}

.member-control-container.info {
  /* trailing info button (absolute position)*/
  margin-right: 40px;
}

.member-control {
  border: solid 1px;
  border-radius: 5px;
  border-color: green;
  color: rgb(114, 113, 113);
  font-weight: 600;
  padding: 5px 8px;
  width: 100%;
}

.member-control-container select {
  cursor: pointer;
}


/* .member-control::placeholder {
  font-weight: 500;
  opacity: 0.6;
  color: black;
} */

.member-control .option {
  font-size: 1.4rem;
  font-weight: 600;
}

.member-control-container i {
  /*trash icon positioning*/
  position: absolute;
  cursor: pointer;
  color: #7aa095;
  right: 7px;
  top: 10px;
}

.member-control-container .aft-label {
  position: absolute;
  top: 20%;
  right: -30%;
  color: black;
  font-weight: 600;
}

.member-form-container input[type="checkbox"] {
  position: absolute;
  width: 15px;
  cursor: pointer;
}

.member-form-container input[type="checkbox"].abo-newsletter {
  left: 200px;
  margin-left: 40px;
}

.member-form-container input[type="checkbox"].dates-type {
  margin-left: 5px;
}

/* PROFILE & CORPORATE DATA TOOLBAR */
div.member-form-container.corporate{
  margin-top: 40px;
}
#tab8-panel {
  margin-top: 0 !important;
}
.profile div.account{
  margin-top: 50px;
}
.member-form-toolbar.corporate,.member-form-toolbar.profile {/*common toolbar with CorporateData*/
  position: fixed; /* toolbar is removed from grid normal flow */
  right: 35px;/* toolbar top position handled in CorporateData.jsx and Profile.jsx*/
  width: 200px;
}
div.member-control-container.profile-PH {
  grid-column: 1/-1;
  max-width: 1000px;
  justify-content: center;
  margin:20px auto;
}
@media screen and (min-width: 601px) {
  .member-form-toolbar.corporate.pro_admin,
  .member-form-toolbar.profile.pro_admin {
    right: 30px;
  }
}

@media screen and (min-width: 668px) {
  .profile div.account {
    margin-top: 0px;
  }
}

@media screen and (min-width: 1159px) {
  .profile div.account-wrapper {
    margin-top: 15px;
  }
}
@media screen and (min-width: 1200px){
  div.member-form-container.corporate {
    margin-top: 20px;
  }
}
/* PROFILE ACCOUNT HEADER */
.profile div.account {
  display: grid;
  grid-template-rows: (1 12px, 1 auto);
  grid-template-columns: 33fr 33fr 33fr;
  align-items: top;
  row-gap: 5px;
  column-gap: 10px;
}

div.account :nth-child(2) {
  grid-column: span 2;
}

div.account .status {
  grid-column: 1;
}

div.status,
div.registration {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

div.password {
  height: 60%;
  align-self: flex-end;
}

@media screen and (min-width: 480px) {
  .profile div.account-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: left;
  }

  .profile div.account {
    grid-template-columns: 80px 110px 200px;
  }

  .member-form-container .separator-first {
    margin: 10px 0;
  }
}

@media screen and (min-width: 1196px) {
  .profile div.account {
    grid-template-columns: 80px 110px 150px 140px 190px 220px;
  }

  div.account .status {
    grid-column: 4;
  }

  div.status,
  div.registration {
    flex-direction: row;
  }

  div.password {
    height: 90%;
    grid-column:6;
    grid-row: 1/span 2;
    align-self:flex-start;
    width:85%;
    max-width: 190px;
    white-space: pre-wrap !important;
    margin-left: 20px;
  }
}
@media screen and (min-width: 1200px) {
  .profile div.account {
    grid-template-columns: 80px 110px 150px 140px 190px 200px 350px;
  }

  div.password {
    margin-bottom: 0px;
    padding: 5px 0 0;
  }
}


/* PROFILE ACCOUNT OWNER */
.profile div.account-owner {
  display: grid;
  grid-template-rows: (1 12px, 1 auto);
  grid-template-columns: 100fr;
  align-items: top;
  row-gap: 5px;
  column-gap: 20px;
}

#member-profile-control-preferredLanguage-select{
  width:60px;
}
div.preferredLanguage-container .MuiPaper-root{
  width:250px;
  margin:0;
}
#member-profile-control-birthdate,
#member-profile-control-sex,
#member-profile-control-height,
#member-profile-control-weight {
  width: 35%;
}

#member-profile-control-code_parrainage_used {
  width: 95%;
}

@media screen and (min-width: 480px) {
  .profile div.account-owner {
    grid-template-rows: auto;
    grid-template-columns: 20fr 50fr;
    align-items: top;
    row-gap: 10px;
  }

  .member-label {
    padding: 3px 5px;
  }

  .profile div.account-owner .d-flex.aboNewsletter {
    grid-column: span 2;
  }
}

@media screen and (min-width: 768px) {
  .profile div.account-owner {
    grid-template-columns: 10fr 25fr 10fr 25fr;
  }

  #member-profile-label-address,
  #member-profile-label-sex,
  #member-profile-label-diet,
  #member-profile-label-code_parrainage_used {
    grid-column-start: 1;
  }

  #member-profile-label-postcode,
  #member-profile-label-city,
  #member-profile-label-country {
    grid-column-start: 3;
  }

  #member-profile-control-address.member-control-container {
    grid-row-start: span 3;
    margin-bottom: 0px;
  }

  #member-profile-control-address textarea {
    height: 140px;
    margin-bottom: 0px;
  }

  #member-profile-control-country {
    grid-column-start: 4;
  }
}

@media screen and (min-width: 1200px) {
  .profile div.account-owner {
    grid-template-columns: 10fr 20fr 10fr 20fr 10fr 20fr;
  }

  .profile div.account-owner .d-flex.aboNewsletter {
    grid-column: 4;
  }
}

@media screen and (min-width: 1400px) {
  .profile div.account-owner {
    grid-template-columns: 6.5fr 20fr 6.5fr 20fr 6fr 20fr 6fr 10fr;
  }
}

/* PROFILE PARTICIPANTS */
div#member-profile-label-button-participantsList {
  grid-column-start: 1;
  display: flex;
  align-items: center;
  height: 30px;
}

#member-profile-label-button-participantsList button {
  text-align: center;
  width: 20%;
}

.profile div.account-owner div.participantsList-container {
  grid-column: 1;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}

.profile div.account-owner .accordion-details {
  height: 25vh;
  padding: 0 20px 0 10px;
  overflow-y: auto;
}

@media screen and (min-width: 480px) {
  .profile div.account-owner div.participantsList-container {
    grid-column: 2/-1;
  }

  div#member-profile-label-button-participantsList {
    flex-direction: column;
    align-items: flex-start;
  }

  #member-profile-label-button-participantsList button {
    width: 90px;
    margin-left: 5px;
  }
}

@media screen and (min-width: 768px) {
  #member-profile-label-button-participantsList {
    grid-column-start: 1;
  }

  .profile div.account-owner div.participantsList-container {
    grid-column: 2/-1;
    flex-direction: row;
  }

  div.participantsList-container .participantDetails {
    width: 40%;
  }
}

@media screen and (min-width: 1200px) {
  div.participantsList-container .participantDetails {
    width: 25%;
  }
}

@media screen and (min-width: 1400px) {
  div.participantsList-container .participantDetails {
    width: 20%;
  }
}

/* CORPORATE DATA */

.member-form-container .corporate-data {
  display: grid;
  grid-template-rows: (1 12px, 1 auto);
  grid-template-columns: 100fr;
  align-items: top;
  row-gap: 5px;
  column-gap: 20px;
}

#member-corporate-control-code_parrainage_used {
  width: 95%;
}

.corporate div.rib,
.corporate div.check {
  grid-column: 1/-1;
  display: flex;
  flex-wrap: wrap;
}

div.rib .rib-element {
  margin-right: 20px;
}

#member-corporate-control-bankCode,
#member-corporate-control-guichetCode {
  width: 130px;
}

#member-corporate-control-keyAccount {
  width: 70px;
}

div.check .check-element {
  margin-right: 0px;
  position: relative;
  width: 100%;
}

div.check-element .glyphicon {
  position: absolute;
  top: -2px;
  right: 20px;
  font-size: 2rem;
  color: #7aa095;
  cursor: pointer;
}

.corporate div.sponsorship {
  grid-column: 1/-1;
  display: flex;
  flex-direction: column;
}

#member-corporate-control-code_parrainage {
  width: 120px;
}

#member-corporate-control-code_parrainage_given,
#member-corporate-control-code_parrainage_given_to_pro {
  width: 50px;
}

.corporate .aft-label {
  top: 2px;
  right: -55px;
  white-space: nowrap;
  color: #7aa095;
}

div.member-control.sponsor-input-label {
  grid-column: 1/-1;
  border-width: 2px;
  border-radius: 10px;
  display: flex;
  margin: 10px auto 0;
}

.member-control.sponsor-input-label .input-ph {
  width: 100%;
  text-align: center;
  border: 0;
  background-color: transparent;
}

.member-control.sponsor-input-label .input-ph::placeholder,
.member-control::placeholder {
  width: 100%;
  color: #7aa095 !important;
  font-size: 1.5rem;
  font-weight: 900;
  filter: brightness(110%);
  text-align: center;
  opacity:0.6;
}
p#daily-program-PH, p#options-PH,p#images-PH,
p#profile-PH, p#external-PH,p#no-dates-PH {
  color: blueviolet ;
  opacity:0.6;
  text-align: center;
  background-color: rgb(220, 241, 197);
  cursor: not-allowed;
  margin:0;
}
p#no-dates-PH {
  text-align: justify;
  width:50%;
}

@media screen and (min-width: 480px) {
  .member-form-container .corporate-data {
    grid-template-rows: auto;
    grid-template-columns: 20fr 50fr;
    align-items: top;
    row-gap: 10px;
  }

  .corporate div.rib,
  .corporate div.check {
    grid-column: 2/-1;
  }

  .corporate div.sponsorship {
    flex-direction: row;
    align-items: top;
    flex-wrap: wrap;
  }

  .member-label {
    padding: 3px 5px;
  }
}

@media screen and (min-width: 768px) {

  .member-form-container .corporate-data {
    grid-template-columns: 10fr 25fr 10fr 25fr;
  }

  #member-corporate-label-address,
  #member-corporate-label-code_parrainage_used {
    grid-column-start: 1;
  }

  #member-corporate-label-postcode,
  #member-corporate-label-city,
  #member-corporate-label-country {
    grid-column-start: 3;
  }

  #member-corporate-control-address.member-control-container {
    grid-row-start: span 3;
    margin-bottom: 0px;
  }

  #member-corporate-control-address textarea {
    height: 140px;
    margin-bottom: 0px;
  }

  #member-corporate-control-country {
    grid-column-start: 4;
  }

  #member-corporate-label-bic {
    text-align: right;
  }

  #member-corporate-label-check {
    grid-column-start: 1;
  }

  div.check .check-element {
    margin-right: 50px;
    width: 40%;
  }

  #member-corporate-control-code_parrainage {
    margin-right: 30px;
  }

  .member-control.sponsor-input-label {
    width: 95%;
  }
}

@media screen and (min-width: 1200px) {
  #tab7-panel {
    margin-top: 30px;
    height: 72vh;
  }

  .member-form-container .corporate-data {
    grid-template-columns: 10fr 20fr 10fr 20fr 10fr 20fr;
  }

  div.check .check-element {
    width: 30%;
  }

  .member-control.sponsor-input-label {
    width: 1000px;
  }
}

@media screen and (min-width: 1400px) {
  .member-form-container .corporate-data {
    grid-template-columns: 8fr 20fr 5fr 20fr 2fr 20fr 4fr 10fr;
  }

  div.check .check-element {
    width: 20%;
  }
}
/* ANNOUNCES TAB */
/* div.announces-table-container{
  position:sticky; 
  top:50px;
}
div.announces-table-container div.announces-table {
  overflow-y: "auto";
  height:80vh;
}
div.announces-table div.mui-table-body{
  overflow-y: "auto"!important;
  width:100%;
  height: 50vh !important;

} */


/* ANNOUNCE FORM */
.member-form-toolbar-wrapper{
  display:flex;
  justify-content: left;
  position: fixed;
  top: 60px;
  z-index: 100;
}
div.member-form-toolbar.announce.lh1 .badge.rounded-pill{
  margin-top: -15px;
  margin-right:10px;
  min-width:25px;
}
@media (min-width: 950px) {
  div.member-form-toolbar.announce.lh1 .badge.rounded-pill::after {
    content: "ction" !important;
  }
}
.member-form-toolbar-container.announce {
  position:relative;
  display: grid;
  grid-template-rows: (2 45);
  grid-template-columns: 415px;
  row-gap: 2px;
  column-gap: 5px;
  z-index: 100;
}
.member-form-toolbar-container i{
  cursor: pointer;
}

.member-form-toolbar.announce {
  padding: 5px 15px;
}
.member-form-toolbar.announce.rh{
  display:flex;
  margin:0;
  padding:0;
  border:none;
}
@media (min-width: 1000px){
  .member-form-toolbar.announce.rh{
    margin-left:auto;
  }
}
@media (min-width: 1400px) {
  .member-form-toolbar.announce.rh {
    margin-right:30px;
  }
}
.member-form-toolbar.announce.rh3 {
  padding: 7px 15px 3px;
}

.member-form-container .announce-form {
  display: grid;
  grid-template-rows: (1 12px, 1 auto);
  grid-template-columns: 100fr;
  align-items: top;
  row-gap: 5px;
  column-gap: 20px;
  padding-top:130px;
}

.member-control-container.lang {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.member-control-container.lang i {
  /*trash icon positioning*/
  top: 30px;
}

.member-form-container div.translate {
  display: flex;
  justify-content: center;
  align-items: center;
}

div.translate .glyphicon.translate-icon {
  font-size: 2rem;
  color: #7aa095;
  cursor: pointer;
  transform: rotateZ(-90deg);
}

div.translate .translate-text {
  font-size: 1.3rem;
  margin: 10px;
}

div.announce-items-group {
  display: flex;
  flex-direction: column;
}

div.simple-text-block {
  display: inline-block;
  margin: 3px 10px 0 10px;
  width: 70%;
}

.announce-form div.dropdown {
  max-width: 300px;
}

.announce-form div.dropdown i {
  /*trash icon positioning*/
  position: absolute;
  cursor: pointer;
  color: #7aa095;
  top: 10px;
  right: 5px;
}

#member-announce-control-lat,
#member-announce-control-lng {
  width: 130px;
}

#btn-update-GPS {
  margin: 5px 15px 0;
  width: 120px;
  height:35px;
}

#member-announce-control-participantMin,
#member-announce-control-participantMax,
#member-announce-control-ageMinParticipant,
#member-announce-control-ageMaxParticipant,
#member-announce-control-childUnderOf {
  width: 50px;
}

#member-announce-control-devise,
#member-announce-control-priceAdulte,
#member-announce-control-priceChild,
#member-announce-control-priceAccompagnateur {
  width: 80px;
}

.announce-items-group .announce-items-subgroup {
  display: flex;
  flex-direction: column;
}

.announce-items-group .announce-items-subgroup div.days-nights {
  display: flex;
  justify-content: flex-start;
  margin-left: 15px;
}

.simple-text-block.dates-type {
  display: flex;
}

.simple-text-block .aft-label {
  color: black;
  font-weight: 600;
  margin: 5px 10px 0 10px;
}

.member-control#nbDays,
.member-control#nbNights {
  width: 40px;
  text-align: center;
}
.member-control#nbNights {
  width: 65px;
}

.member-control#nbNights {
  margin-left: 15px;
}

.announce-items-subgroup.calendar-block {
  flex-direction: column;
}

div.calendar-range-pro,
div.dates-table {
  width: 300px;
  overflow: auto;
}
.announce-items-group.day-option-header .member-control-container,
.announce-items-group.images-header .member-control-container{
  width:100%;
  max-width: 1000px;
}
.announce-items-day-option-unit {
  border: 1px solid;
  border-color: green;
  border-radius: 5px;
  display: grid;
  grid-template-rows: (1 12px, 1 auto);
  grid-template-columns: 100fr;
  align-items: top;
  row-gap: 5px;
  column-gap: 20px;
}
.announce-items-day-option-unit .member-control-container {
  margin: 0 15px;
}
div.equi-hours-kms{
  display: flex;
  flex-direction: column;
  justify-content: left;
}
div.equi-hours-kms .member-control-container{
  width: 120px;
  margin:0 20px;
}
div.option-price {
  display: flex;
}
div.option-price .member-control-container:first-child {
  width: 80px;
}
div.option-price .member-control-container #type {
  width: 120px;
  margin-left:10px;
}
div.announce-items-group.images{
  display:flex;
  flex-wrap: wrap;
  justify-content:left
}
div.announce-items-group.images-container .image-container{
  display:flex;
  flex-direction: column;
  justify-content:top;
  border:1px solid;
  border-radius: 5px;
  width:340px;
  margin:5px;
  /* padding-bottom: 10px; */
}
div.announce-items-group.images-container #image-file-control-name{
  width:85%;
}
@media screen and (min-width: 480px) {
  .member-form-toolbar-wrapper {
    justify-content: center;
    width:100%;
  }
  .member-form-container .announce-form{
    padding-top: 170px;    
  }
  .member-form-container .announce-form .member-label {
    grid-column: 1;
  }
  .member-control-container.lang,
  .member-form-container div.translate {
    grid-column: 2;
  }
  div.announce-items-group {
    grid-column: 2/-1;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: left;
    margin-top: 10px;
  }  
  div.simple-text-block {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    width: auto;
  }
  #btn-update-GPS {
    margin: 3px 15px;
  }
  .announce-form div.dropdown {
    width: 300px;
  }
  .announce-items-group .announce-items-subgroup div.days-nights {
    margin-top: 10px;
  }
  .announce-items-group.day-option-header {
    flex-wrap: nowrap;
  }
  .announce-items-day-option-unit {
    grid-column: 2/-1;
    grid-template-columns: 130px auto 0px;
  }
  .announce-items-day-option-unit .member-control-container {
    margin: 0;
  }
  .announce-items-day-option-unit .member-label {
    margin: 0 0 0 10px;
  }
  .announce-items-day-option-unit div.equi-hours-kms {
    grid-column: 1/-1;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: left;
    align-items:flex-start;
    margin: 20px 0;
  }
  .announce-items-day-option-unit div.option-price {
    grid-column: 2/-1;
    justify-content: left;
    margin: 10px 0;
  }
  div.option-price .member-control-container #type{
    margin-left:50px;
  }
}
@media screen and (min-width: 700px) {
  div.calendar-range-pro,
  div.dates-table {
    width: 500px;
  }
  .announce-items-group.day-option-header .member-control-container {
    margin-left: 40px;
  }
}
@media screen and (min-width: 768px) {
  .member-form-toolbar-wrapper{
    top:70px;
  }
  
  .member-form-container .announce-form {
    row-gap: 15px;
  }
  .member-form-container .announce-form {
    grid-template-columns: 145px 30fr 110px 30fr;
    padding-top:175px;
  }
  .member-form-container div.translate {
    grid-column-start: 3;
  }
  .member-control-container.lang,
  .member-form-container div.translate {
    grid-column: auto;
  }
  div.translate .glyphicon.translate-icon {
    transform: rotateZ(0deg);
  }
  div.calendar-range-pro,
  div.dates-table {
    width: 600px;
  }
  .announce-items-day-option-unit {
    grid-column: 2/-1;
    grid-template-columns: 130px 30fr 110px 30fr 0px;
  }
  .announce-items-day-option-unit .member-control-container {
    width: 100%;
  }
}
@media screen and (min-width: 900px) {
  .member-form-container .announce-form{
    padding-top: 140px;
  }
  .member-form-toolbar-container.announce{
    display:flex;
    justify-content:space-between;
    width:100%;
    padding: 0 30px;
  }
  div.calendar-range-pro,
  div.dates-table {
    width: 700px;
  }
}
@media screen and (min-width: 1000px) {
  .member-form-toolbar-container.announce {
    padding: 0 60px 0 55px;
  }
}
@media screen and (min-width: 1200px) {
  .announce-items-group.dates .announce-items-subgroup.dates-type {
    margin-right: 100px;
  }
  div.calendar-range-pro,
  div.dates-table {
    width: 800px;
  }
}
@media screen and (min-width: 1400px) {
  .member-form-toolbar-container.announce {
    padding: 0 70px 0 40px;
  }
  .member-form-container .announce-form{
    margin:0 50px 0 40px;
  }
  .announce-items-subgroup.calendar-block {
    flex-direction: row;
  }
  div.calendar-range-pro {
    width: fit-content;
  }
  div.dates-table {
    margin-top: 15px;
    width: 640px;
  }
  .announce-items-group.day-option-header .member-control-container {
    margin-left: 40px;
  }  
}
/* MESSAGING TAB */
.member-tabs #tab6-panel::-webkit-scrollbar {
  display: none;
}
/* MY CONDITIONS TAB */
@media screen and (min-width: 1400px){
  .member-tabs #tab5-panel{
    padding-left:30px ;
    margin-left:50px;
  }
}
.member-tabs #tab5-panel div.tab-pane{
  background-color: transparent;
}
.member-tabs #tab5-panel div.member-conditions-container div.member-pro-conditions {
  border:solid 1px;
  border-radius: 5px;
  border-color: #D9D9D9;
  min-width: 200px;
  max-width:950px;
  background-color: #F2F2F2;
  margin-bottom: 10px;
}
div.member-pro-conditions .conditions-header{
  display:flex;
  align-items: center;
  justify-content:space-between;
  height:47px;
}
.conditions-header h1.company-name{
  font-size: 1.5rem !important;
  text-transform: capitalize !important;
  font-weight: 600;
  cursor:pointer;
  margin:15px;
}
.conditions-header .GTC-chapter4{
  margin-left:15px;
  font-size: 1.5rem;
}
.GTC-chapter4 .fa-info-circle{
  margin-left: 10px;
  margin-right: 20px;
  color:#7aa095;
}
.conditions-header .fa-save{
  color:#ccc;
  cursor:pointer;
  padding: 5px 15px;
}
div.member-pro-conditions h2.title{
  font-size:1.5rem !important;
  text-align: left;
  font-weight:600;
  width:100%;
  height:40px;
  border:none;
  min-width:200px;
  cursor:default;
}
div.member-pro-conditions div.conditions-details-wrapper{
  display:flex;
  flex-wrap: wrap;
}
div.member-pro-conditions div.conditions-details-wrapper div#pro-sponsoring.conditions-details{
  width:60px;
}
div.member-pro-conditions div.conditions-details-wrapper div.conditions-details {
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 134px;
}
div.member-pro-conditions div.conditions-details-container{
  display:flex;
  justify-content: left;
  border:1px solid;
  border-radius: 5px;
}
div.conditions-details-container h3.row-header{
  font-size: 1.5rem;
  text-transform: lowercase;
  font-weight: 500;
  padding:15px 5px;
  margin:0;
  border-right: 1px solid;
  min-width: 100px;
  width:100px;
}
div.conditions-details-container .conditions-details{
  display:flex; 
  flex-wrap: wrap;
  background-color: #fff;
  border-radius: 5px;
  padding:5px 0;
  min-width:100px;
  width:100%;
  border-radius: 0 5px 5px 0;
}
div.conditions-details .element{
  display:flex;
  flex-direction: column;
  align-items:center;
  margin:5px 15px;
}
div.conditions-details .element .column-header,div.conditions-details-container .element .column-header{
  text-transform: capitalize;
  font-size: 1.4rem;
  margin:0 0 5px 0;
  font-weight: 600;
}
div.conditions-details .element .member-control-container{
  width:50px;
}
div.conditions-details .element .member-control{
  height:25px;
  width: 50px;
  text-align: center;
}
div.conditions-details .element .member-control-container .aft-label{
  top:2px;
}
div.conditions-details-container .conditions-details.annual{
  padding:12px 15px;
  font-weight:600;
  font-size: 1.5rem;
}
div.conditions-details-container .conditions-details .url-unit{
  display:flex;
  flex-wrap: wrap;
  align-items:center;
  padding:5px 10px;
}
div.conditions-details .url-unit>button{
  width:100px;
  height:35px;
  margin-right:5px;
}
div.conditions-details .url-unit .member-control-container{
  width:250px;
}
div.conditions-details .url-unit .url-status{
  line-height: 18px;
  padding:0px 15px;
}
div.conditions-penalty div.conditions-details-container{
  display:block;
}
div.conditions-penalty div.conditions-details-container div.penalty-unit{
  display:grid;  
  grid-template-rows: auto;
  grid-template-columns: 40fr 60fr;
  width:100%;
  border-bottom: 1px solid;
}
div.conditions-penalty div.conditions-details-container div.penalty-unit:last-of-type{
  border-bottom:none;
}
h3.row-header#penalty-reason{
  grid-column: 1;
  grid-row:1/-1;
  padding-top: 10px;
  padding-bottom: 0;
  width: 100%;
}
div.penalty-unit div.conditions-details {
  flex-direction: column;
  justify-content: center;
}
div.penalty-unit div.conditions-details div.item-unit{
  display:flex;
  flex-wrap: wrap;
  align-items:top
}
div.member-pro-conditions div.conditions-penalty button {
  border-color: #fff !important;
  border-radius: 5px !important;
  min-width: 80px;
  height: 34px;
}
@media screen and (min-width: 768px){
  div.conditions-details-container h3.row-header:not(#penalty-reason){
    min-width: 160px;
    width: 160px;
    padding: 10px;
  }
}
@media screen and (min-width:1000px) {
  div.conditions-details-container h3.row-header:not(#penalty-reason) {
    min-width: 180px;
    width: 180px;
  }
}
@media screen and (min-width:1400px) {
  div#tab5-panel .MuiPaper-root {
    width: 80%;
  }
  div.conditions-details-container div.item-unit h3.row-header:not(#penalty-reason){
    min-width: 220px;
    width: 220px;
  }
}
/* MY BOOKINGS TAB */
#bkgActionSlider0,#bkgActionSlider1 {
  background-color: none;
}
#bkgActionSlider0:checked+.slider,#bkgActionSlider1:checked+.slider{
  background-color: red;
  opacity: 0.5;
}
div#tab3-panel div.tab-pane.active div.d-flex span{
  margin:auto 0;
}
div#tab3-panel div.tab-pane.active div.dropdown.active:not(.confirmation){
  display:block;
  height:30px;
  text-align: center;
}
div#tab3-panel div.tab-pane.active div.dropdown:not(.active):not(.confirmation){
  display: none;
  height:20px;
}