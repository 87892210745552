/* GOOGLE fonts */
@import url('https: //fonts.googleapis.com/css?family=Montserrat:400,600,700');

/*COMMON*/
img.fit-picture {/*used in star ratings*/
  object-fit: cover;
  width:18px;
  height:18px;
  margin:0 2px;
}
.separator {
  display: flex;
  align-items: center;
  text-align: center;
}

.separator::before,
.separator::after {
  content: '';
  flex: 1;
  border-bottom: 2px solid #7aa095;
}

.separator:not(:empty)::before {
  margin-right: 1rem;
}

.separator:not(:empty)::after {
  margin-left: 1rem;
}
/*APP PAGE LOADER*/
div.main-wrapper div.container-loader {
  position: fixed;
  top: 15vh;
  left: 40%;
  z-index: 500;
}
div.container-loader svg{
  width:50px;
  height:50px;
}
@media (min-width: 380px){
  div.main-wrapper div.container-loader{
    left: 43%;
  }

}
@media (min-width: 768px) {
  div.main-wrapper div.container-loader {
    left: 45%;
  }

}
@media (min-width: 1000px) {
  div.main-wrapper div.container-loader {
    top:25vh;
    left: 47%;
  }

}
@media (min-width: 1400px) {
  div.main-wrapper div.container-loader {
    top: 25vh;
  }
  div.container-loader svg {
    width: 70px;
    height: 70px;
  }

}

/* FOOTER */
div.footer {
  display:flex;
  justify-content: center;
  padding:10px;
}
div.main-footer {
  background-color: #262a2e;
  display: grid;
  grid-template-rows:repeat(4 auto);
  grid-template-columns: 20fr 40fr 40fr;
  row-gap: 10px;
  column-gap: 20px;
  padding: 0 10px;
  justify-items: center;
}
div.main-footer .title {
  color: #ffffff;
  font-size: small;
  grid-column: 1;
  justify-self: left;
}
div.main-footer p{
  margin:0;
  text-align:justify;
}
div.main-footer ul.social{
  display:flex;
  width:80%;
  justify-content: space-evenly;
}
div.main-footer ul.social i{
  padding-right: 10px;
}
div.main-footer ul.contact {
  width: 95%;
}
div.main-footer ul.contact i{
  padding-right: 5px;
}
div.main-footer .t3,div.main-footer .p3,div.main-footer .newsletter,
div.main-footer .hide-elt{
  display:none !important;
}
div.main-footer .copyRight-text{
  grid-column: 1/-1;
}
div.main-footer .copyRight-text p {
  color: #ffffff;
  text-align: center;
}
div.main-footer .bottom-links {
  grid-column: 1/-1;
  display:flex;
  justify-content: center;
}
div.main-footer .bottom-links a{
    padding-right:30px;
    color: #ffffff;
}
div.main-footer .input-group{
  height:34px;
}
@media (min-width: 700px){
  div.main-footer .hide-elt{
    display:inline-block !important;
  }
}
@media (min-width: 1000px){
  div.footer{
    padding: 0 0 15px;
  }
  div.main-footer{
    grid-template-rows: auto auto auto auto ;
    grid-template-columns: 268px 259px 259px;
    column-gap: 30px;
  }
  div.main-footer .title {
    justify-self: center;
    align-self: center;
    font-size: medium;
  }
  div.main-footer .title.t1{
    grid-area: 1/1;
  }
  div.main-footer .title.t2 {
    grid-area: 1/2;
  }
  div.main-footer .title.t3 {
    display:block !important;
    grid-area: 1/3;
  }
  div.main-footer .p1 {
    grid-area: 2/1;
  }
  div.main-footer .p2 {
    grid-area: 2/2;
  }
  div.main-footer .p3 {
    display: block !important;
    grid-area: 2/3;
  }
  div.main-footer .ul1 {
    grid-area: 3/1;
    text-align: center;
  }
  div.main-footer .ul2 {
    grid-area: 3/2;
    padding-bottom: 10px;;
  }
  div.main-footer .input-group.newsletter {
    display: table !important;
    grid-area: 3/3;
  }
}
@media (min-width: 1200px){
  div.main-footer{
    column-gap: 50px;
  }
}
@media (min-width: 1400px){
  div.main-footer{
    grid-template-columns: 330px 330px 330px;
  }
  div.main-footer .copyRight-text {
    grid-column: 1/span 2;
    justify-self: left;
  }
  div.main-footer .bottom-links {
    grid-column: 3/span 2;
    justify-content: right;
    overflow-x: visible;
  }

  div.main-footer .bottom-links a {
    padding-right: 10px;
  }
}
/* CGU-CGV */
div.warning-CGU-CGV-legal-notice{
  font-size: 1.7rem;
  font-weight: 600;
  color:orange;
  border:2px solid;
  border-color: orange;
  border-radius: 5px;
  padding:10px 15px;
  margin:20px auto 0;
  max-width: 1000px;
  text-align: justify;
}
div.container.CGU-CGV,div.container.legal-notice{
  height:70vh;
  padding-right:50px;
  overflow-y:scroll;
  margin:0 auto 40px;
}

/*ANNOUNCES*/
label.form-check-label.star {/* FILTER POPPER (STAR RATING) */
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  height: 25px;
  padding-bottom: 5px;
}
#announces-container div#filter-sort {
  margin:65px 2px 0;
  padding:0 20px;
  border:1px solid;
  border-radius: 10px;
  border-color: #969696;
}
@media (min-width: 768px){
  #announces-container div#filter-sort{
    margin-top: 76px;
  }
}
#announces-container div#filter-sort div.always-visible{
  display:flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding-top: 10px;
}
@media (min-width: 516px){
  #announces-container div#filter-sort div.always-visible{
    padding-top: 0;
  }
}
div#filter-sort label{
  font-size: 1.3rem;
  min-width:75px;
}
div#filter-sort .search-group{
  display: none;
  align-items: top;
  flex-wrap: wrap;
  margin-bottom: 10px;
}
.search-group div.ReactTags__tagInput{
  margin: 0 ;
}
div#filter-sort div.toolbar-info{
  /* width:100%; */
  display:flex;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  color:blue;
  font-weight: 550;
  /* padding:0 0 10px; */
}
div#filter-sort div.toolbar-info .MuiSvgIcon-root{
  cursor: pointer;
  width:40px;
  height:40px;
  padding:5px;
  color:#fff;
  background-color: #7AA095;
  border-radius: 0 50% 50% 0;
}
div#filter-sort div.toolbar-info .MuiSvgIcon-root:hover{
  opacity:0.8;
}
div#filter-sort div.toolbar-info #filterReset{
  border-radius: 50% 0 0 50%;
}
div#filter-sort div.toolbar-info span{
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width:200px;
  height:40px;
  border:1px solid;
  border-left:0;
  border-right: 0;
  background-color: #f7fcf8;
  font-size: 1.7rem;
}
div#filter-sort div.criteria-summary{
  width: 100%;
  display: none;
  flex-wrap: wrap;
  justify-content: left;
  align-items: top;
  /* padding: 10px 5px 0; */
}
.criteria-summary div.textarea.criteria-summary{
  resize:both;
  max-width: 700px;
  height:70px;
  margin-left:0 !important;
}
div#filter-sort .search-group{
  width:100%;
  margin-bottom:0;
}
.search-group button#search,.search-group button#clearAll,.criteria-summary button#filterReset{
  height:35px;
  margin-left: 5px;
  margin-top: 5px;
}
div#filter-sort .filters-container {
  display: none;
  align-items: center;
  flex-wrap: wrap;
}
div#filter-sort i#search-chevron,div#filter-sort i#criteria-summary-chevron {
  margin: 15px 10px 0 0;
}
div#filter-sort i#filter-chevron,div#filter-sort i#sortBy-chevron,div#filter-sort i#sortOrder-chevron {
  margin: 0 5px 0 0;
}

div#filter-sort i#search-chevron.fa-chevron-right ~ button,
div#filter-sort i#search-chevron.fa-chevron-right ~ div.ReactTags__tags,
div#filter-sort i#criteria-summary-chevron.fa-chevron-right~div,
div#filter-sort i#filter-chevron.fa-chevron-right~button,
div#filter-sort i#sortBy-chevron.fa-chevron-right ~ button,
div#filter-sort i#sortOrder-chevron.fa-chevron-right ~ button{
  display:none;
}
div#filter-sort i#search-chevron.fa-chevron-down ~ button,
div#filter-sort i#search-chevron.fa-chevron-down ~ div.ReactTags__tags,
div#filter-sort i#criteria-summary-chevron.fa-chevron-down~div,
div#filter-sort i#filter-chevron.fa-chevron-down ~ button,
div#filter-sort i#sortBy-chevron.fa-chevron-down ~ button,
div#filter-sort i#sortOrder-chevron.fa-chevron-down ~ button {
  display: block;
}
div#filter-sort .sort-container{
  display: none;
  flex-wrap: wrap;
  justify-content: space-between;
}
div#filter-sort .sort-container :nth-child(7){/* PHYSICAL LEVEL*/
  margin-right:45px !important;
}
div#filter-sort .sort-group i#sortBy-chevron.fa-chevron-right {
  margin-right: 15px;
}
div#filter-sort .sort-container .sort-group{
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
div#filter-sort .sort-container .sort-order-group {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  margin-right: 120px;
}
div#filter-sort .mode-display-container{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  color: #7aa095;
  margin:0 30px;
  padding-bottom: 5px;
}
div.mode-display-container div.mode-display-group{
  display: flex;
  align-items: center;
  justify-content: space-between;
  width:130px;
  padding:10px 0 5px;
}
div.mode-display-container div.mode-display-group img{
  width:30px;
  margin:5px 0px 5px 5px;
}
div.mode-display-container div.mode-display-group svg.MuiSvgIcon-root{/*top of page icon*/
  cursor:pointer;
  font-size: 35px;
  margin-left:0;

}
div.mode-display-container div.mode-display-group div {
  cursor: pointer;
  opacity:0.5;
  width: 45px;
  padding:0 2px;
  margin-bottom: 5px;
}
.gm-svpc div {/*Necessary to get pegman icon properly displayed*/
  width: 40px;
  height: 40px;
}
.mainContentSection.packagesSection div.map-container {
  display: flex;
  border-radius: 5px;
  width:100%;

}
 div.mode-display-container div.mode-display-group div#list-map-view-icon {
   display: flex;
   flex-wrap: nowrap;
   justify-content: space-between;
   width: 78px;
   overflow: hidden;
 }
@media (min-width: 1400px){
  div.mode-display-container div.mode-display-group{
    width:200px;
  } 
}

div.mode-display-container div.mode-display-group div.active {
  opacity: 1;
  border-radius: 5px;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.7);
  border: 1px solid;
}

.mainContentSection.packagesSection .announces-list {
  display:flex;
  flex-wrap: wrap;
  height: 80vh;
  overflow-y: scroll;
  overflow-x:hidden;
  width:100%;
  max-width: 1200px;
}
.mainContentSection.packagesSection .announces-list.thumbnails{
  overflow-y: auto;
  max-width: 100%;
}
ul.pagination {
  margin: 10px;
}

div.small-announce-grid {
  display: grid;
  grid-template-rows: auto 290px;
  grid-template-columns: 100%;
  row-gap: 10px;
  column-gap: 20px;
  margin: 15px 0 15px;
}
div.announces-list .separator{
  padding: 0 10px 15px;
  width: 100%;

}
@media (max-width: 768px){
  div.announces-list:not(.thumbnails) div.small-announce-grid {
    margin:0 15px;
  }
  div.announces-list:not(.thumbnails) .separator{
    width:430px;
  }
  div.announces-list:not(.thumbnails) div.small-announce-grid h4{
    margin:0;
  }
  div.announces-list:not(.thumbnails) div.small-announce-grid div.body-center{
    max-height:280px;
  }
  .small-announce-grid .image-container img {
    max-width: 400px;
  }
}

.small-announce-grid .image-container {
  position: relative;
}

.image-container img {
  object-fit: cover;
  width: 100%;
  height:35vh;
  border-radius: 5px;
}
.small-announce-grid div.body-center{
  max-width: 400px;
}

section#ann-page-header .image-container img{
  height: 46vh;
}

.small-announce-grid .image-container .discountInfo .discountOffer {
  border: 1px solid;
  border-color: #ffffff;
  border-radius: 50%;
  background-color: #7AA095;
  padding: 20px 15px;
}

.small-announce-grid .image-container .discountInfo .discountOffer h4 {
  font-size: 12px;
  font-weight: 600;
  color: #ffffff;
  text-align: center;
  margin: 0;
  text-transform: uppercase;
}

.small-announce-grid .image-container .discountInfo {
  position: absolute;
  top: 30px;
}

.small-announce-grid .image-container .discountInfo.guaranteed-departure {
  left: 30px;
}

.small-announce-grid .image-container .discountInfo.promo {
  right: 30px;
}

.small-announce-grid div.body-center {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.small-announce-grid div.body-right {
  display: flex;
  justify-content: center;
  height:100%;
}

.small-announce-grid div.body-right .bookingDetails {
  display: none;
}

@media (min-width: 768px) {
  div.announces-list.thumbnails{
    width:100%;
    --tn_height:135px;
    --tn_width:125px;
  }
  div.small-announce-grid {
    grid-template-rows: auto ;
    grid-template-columns: 50% 30% 16%;
    column-gap: 20px;
    margin: 0px 10px 18px;
    width:100%;
  }
  div.announces-list.thumbnails div.small-announce-grid {
    grid-template-rows: auto var(--tn_height);
    grid-template-columns: var(--tn_width) 155px var(--tn_width);
    row-gap: 0px;
    column-gap: 0px;
    padding:10px 5px;
    margin:0;
    width:auto;
  }  
    div.small-announce-grid div.thumbnail-title {
      grid-column: 1/span 3;
      padding-bottom:5px;
      text-align: center;
    }
  div.announces-list.thumbnails div.image-container img{
    height:var(--tn_height);
    width:var(--tn_width);
  }
  
  div.announces-list.thumbnails div.body-center p{
    margin:0 !important;
    width:155px;
    text-align: center;
    font-size: 1.1rem;
  }
  div.announces-list.thumbnails div#ratings-container{
    padding: 0 10px;
  }

  .small-announce-grid .image-container .discountInfo .discountOffer h4 {
    font-size: 15px;
  }
 
  div.announces-list.thumbnails .small-announce-grid div.body-right {
    width: var(--tn_width);
    height:var(--tn_height);
  }
  .small-announce-grid div.body-right .bookingDetails {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px 0;
  }
  div.announces-list.thumbnails .small-announce-grid div.body-right .bookingDetails{
    min-height:auto;
  }
}
@media (min-width: 1400px){
  div#announces-list:not(.thumbnails){
    width:60%;
  }
}
/*ANNOUNCE PAGE*/
#main-wrapper-ann-page::-webkit-scrollbar {
  display: none;
}
div#ann-page{
  position:relative;
}
#ann-content::-webkit-scrollbar {
  display: none;
}
#ann-content{
  height:100vh;
  position:absolute;
  top:85px;
  overflow-x: hidden;
  overflow-y: scroll;
  padding:0 20px;
  width:100%;
}
#ann-content .header-text h1{
  font-size: 2rem;
}
#ann-content .header-text h2 {
  font-size: 2rem;
  text-transform: capitalize;
  white-space: pre-line;
  text-align: center;
  font-weight: 600;
  color:rgb(150, 150, 150);
  margin: 20px 0 30px 0;

}
#ann-content h2.separator{
  text-transform: capitalize;
  font-size: 1.8rem;
  margin:0;
  padding: 15px 0 10px 0;
}
h2.separator::before {
  content:none;
}
h2.separator::after {
  content: '';
  flex: 1;
  border-bottom: 2px solid #7aa095;
}
/* PAGE HEADER + NAVBAR >>> */
#ann-content section#ann-page-header div.carousel-wrapper{
  margin-top: 0px;
}
#ann-content section#ann-page-header div.map-container{
  margin:0 10px;
}

#ann-content section#ann-page-header{
  display:grid;
  grid-template-rows:auto;
  grid-template-columns: 100% ;
  row-gap:20px;
  column-gap: 20px;
  align-items: center;
}
section#ann-dummy {
  height:5px;
}
div#ratings-container{
  display:flex;
  flex-wrap: wrap;
  justify-content:space-between;
  color:#969696;
  margin: auto ;
  padding:10px;
  border:1px solid;
  border-radius: 5px;
  min-height:110px;
  max-width: 400px;
}
div#ratings-container div.rating-content{
  display:flex;
  flex-wrap: nowrap;
  width:100%;
  padding:5px 0;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  font-size: 1.7rem;
}
div#ratings-container div.rating-content.thumbnails{
  justify-content:center;
}
section#ann-hostingBody div#announce-global-rating{
  display: flex;
  align-items: center;
  flex-shrink:0;
  margin-left:50px;
}
nav.ann-navbar {
  font-size: 1.5rem;
  padding: 0 10px;
  z-index: 300;
}
nav.ann-navbar ul.ann-navbar-list {
  display: flex;
  justify-content: left;
  flex-wrap: wrap;
}
nav.ann-navbar ul.ann-navbar-list li {
  width: 18%;
}
nav.ann-navbar ul li {
  cursor: pointer;
  margin:0;
}
nav.ann-navbar li.list-group-item{
  color:black !important;
  background-color: #f0ecec!important;
}
nav.ann-navbar li.list-group-item:first-child{
  border-radius: 5px 0 0 0;
}
nav.ann-navbar li.list-group-item:last-child {
  border-radius: 0 0 5px 0;
}
nav.ann-navbar li.list-group-item:hover{
  /* font-weight:600 !important; */
  background-color: rgb(230, 217, 217) !important;
  border-radius: 5px;
}
@media (min-width: 768px){
  /* div#ratings-container{
    margin-right: 15px;
  } */
  #ann-content section#ann-page-header{
    grid-template-rows: auto auto;
    grid-template-columns: 55% 45%;  
  }
}
/* @media (min-width: 1000px) {
  div#ratings-container {
    margin-right: auto;
  }
} */
@media (max-width: 1000px){
  #ann-content nav.ann-navbar {
    display: none;
  }
  #ann-content.sticky{
    top:85px;}
}
@media (min-width: 1000px) {
  #ann-content section#ann-page-header div.map-container{
    margin:0 25px 0 10px;
  }
  #ann-content nav.ann-navbar {
    display: block;
    padding-top: 15px;
    width:100%;
  }
  #ann-content nav.ann-navbar li.list-group-item.active {
    color: #ffff!important;
    background-color: #7aa095 !important;
    border-radius: 5px;
  }
  #ann-content.sticky section#ann-page-header{
    display:none
  }
  #ann-content section#ann-page-header {
    display:grid;
    grid-template-rows: auto ;
    grid-template-columns: 33% 33% 33%;
  }
  nav.ann-navbar.sticky {
    position:fixed;
    top: 62px;
    left:0;
    right:0;
    margin-bottom: 15px;
  }
  #ann-content.sticky {
    top: 200px;
  } 
  @media (min-width: 1300px){
    #ann-content.sticky {
      top: 170px;
    }
  }
  @media (min-width: 1800px) {
    #ann-content.sticky {
      top: 180px;
    }
  }
}
/* <<< PAGE HEADER + NAVBAR */
div#ann-content div.ann-section-content{
  background-color: #f5f5f5;
  padding:15px 25px;
  border-radius: 5px;
  margin: 0 10px;
}
div#ann-content div.ann-section-content h3{
  text-transform:inherit;
  font-size: 1.7rem;
  color:rgb(150, 150, 150);
  font-weight: 510;
}
div#ann-content div.ann-section-content h4 {
  text-transform: inherit;
  font-size: 1.5rem;
  color: rgb(150, 150, 150);
  font-weight: 510;
  padding-top:5px;
  margin:0;
}
div#ann-content div.ann-section-content p{
  white-space: pre-line;
  text-align: justify;
  color: rgb(150,150,150);
  font-weight: 510;
  padding:0;
  margin:0;
}
div.ann-section-content div.ann-programme-day-title{
  display: flex;
  justify-content: left;
  align-items: center;
  margin-top: 15px;
}
div#ann-content div.ann-section-content h3 ~ p {
  padding:0 10px;
}
div#ann-content div.ann-section-content div.ann-programme-day-title h3 {
  color:#7AA095;
  font-weight: 600;
}
div.ann-section-content div.ann-programme-day-title div.days {
  flex-shrink:0;
  background-color: #ffffff;
  width: 65px !important;
  height: 65px;
  border: 2px solid #7AA095;
  border-radius: 50%;
  line-height: 65px;
  text-align: center;
  text-transform: uppercase;
  color: #7AA095;
  font-size: 1.4rem;
  font-weight: 700;
  margin: 0 20px 10px 0;
}
div#ann-content div.ann-section-content .ann-level-info{
  position:relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
  padding:0;
  margin:0;
}
/* >>> OPTIONS */
div#ann-content div.ann-section-content .ann-options-container{
  display:flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: left;
  padding-left: 20px;
}
div#ann-content div.ann-section-content .ann-options-container .ann-option-container{
  display:flex;
  flex-wrap: wrap;
  padding-bottom: 15px;
}
div.ann-options-container.recap{
  display:flex;
  flex-wrap: wrap;
  justify-content: left;
  width:100%;
}
div.ann-options-container.recap .ann-option-container.recap{
 padding:10px 20px;
}
div.ann-options-container.recap .ann-option-container.recap h4{
  text-transform: capitalize;
  font-size: 1.4rem;
  margin:0;
  padding:5px;
}
/* <<< OPTIONS */

div#ann-content div.table-responsive td{
  padding:10px 10px 10px 0;
}
/* COMMENTS */
div.ann-section-content div.comments-container {
  margin: 20px auto;
}
div.ann-section-content div.comments-container div#no-comments{
 max-height: 80px;
 overflow: hidden;
 text-overflow: ellipsis;
 white-space: nowrap; 
 color:#C25810;
 font-size: 1.5rem;
 font-weight: 550;
 text-align: justify;
 vertical-align: middle;
}
@media screen and (min-width: 375px){
  div.ann-section-content div.comments-container div#no-comments{
    white-space: normal;
  }
}
@media screen and (min-width: 2000px){
  div.ann-section-content div.comments-container{
    margin-left: 40px;
  }
}
/* MESSAGING + FOOTER >>> */
.messaging-container {
  display: grid;
  grid-template-rows: auto 70%;
  grid-template-columns: 100fr;
  row-gap: 20px;
  height:40vh;
}

.messaging-container .messaging-authoring {
  display: flex;
  flex-direction: column;
}

.messaging-container .messaging-authoring .messaging-authoring-title {
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 90%;
}

.messaging-authoring .messaging-authoring-title .post-button {
  width: 90px;
  text-align: left;
}

.messaging-authoring .messaging-authoring-title i {
  position: absolute;
  color: #fff;
  top: 10px;
  right: 5px;
  cursor: pointer;
}

.messaging-container .messaging-authoring label {
  margin: 10px 0 5px 0;
}

.messaging-container .messaging-authoring .form-control {
  color: #0043C8;
  background-color: #E9EDF7;
  border-radius: 5px;
  width: 90%;
}
.messaging-container .messaging-chat-list {
  width: 80%;
  overflow-y:scroll !important;
}
.member-tabs .messaging-container .messaging-chat-list {
  height: 40vh;
}
.ann-section-content .messaging-container{
  padding:0 10px;
}
.ann-section-content .messaging-container .messaging-chat-list {
  height: 37vh;
  margin-top: 10px;
}
@media screen and (max-height: 480px) {
  div.ann-section-content .messaging-container textarea#message {
    height: 60px;
  }

}
@media screen and (max-width: 749px) {
  div.ann-section-content textarea#message {
    height: 60px;
  }
  div.ann-section-content .messaging-container div.messaging-chat-list {
    height: 15vh;
    width:90%;
  }

}

.messaging-container .messaging-chat-list .message-container {
  display: grid;
  grid-template-rows: 50px 50px auto;
  grid-template-columns: 40px auto;
  column-gap: 20px;
  align-items: center;
  padding: 10px;
}

.messaging-container .messaging-chat-list .message-container .message-toolbar {
  display: flex;
  justify-content: left;
  align-items: center;
}
.messaging-chat-list .message-container .form-control.announce-title{
  text-transform: capitalize !important;
  overflow:hidden;
}

.messaging-chat-list .message-container .form-control.title,
.messaging-chat-list .message-container .form-control.announce-title,
.messaging-container .messaging-chat-list .message-container .form-control.message {
  grid-column: 2;
}
.messaging-chat-list .message-container .form-control.title,
.messaging-chat-list .message-container .form-control.announce-title{
  white-space: nowrap;
  text-overflow: ellipsis;
}

@media screen and (min-width: 750px) {
  .messaging-container {
    grid-template-rows: auto auto;
    grid-template-columns: 50fr 50fr;
    padding: 20px;
  }

  .member-tabs .messaging-container .messaging-chat-list {
    grid-row: 1/span 2;
    grid-column: 2;
    height: 65vh;
  }
}

@media screen and (min-width: 1200px) {
  .messaging-container .messaging-chat-list .message-container {
    display: grid;
    grid-template-rows: 50px auto;
    grid-template-columns: 40px 175px auto;
  }

  .messaging-chat-list .message-container .form-control.title {
    grid-column: 3;
  }

  .messaging-chat-list .message-container .form-control.announce-title,
  .messaging-container .messaging-chat-list .message-container .form-control.message {
    grid-column: 2/span 2
  }

}

div#main-wrapper-ann-page div.footer{
  margin-left:-20px;
  width:100vw;
}
/* <<< MESSSAGING + FOOTER */
section.react-modal .flexible-modal-mask{
  position:absolute;
  top: 0px !important;
  left:50px !important;
  background-color: transparent!important;
}
section.react-modal .flexible-modal{
  top:20px !important;
  height: 480px !important;
  width:500px;
  z-index:200;
  border-radius: 5px;
}
section.react-modal .flexible-modal-drag-area-left,section.react-modal .flexible-modal-drag-area-right,
section.react-modal .flexible-modal-drag-area-bottom{
  height:10px !important;
  background-color: transparent !important;
}
section.react-modal .flexible-modal-drag-area{
  height: 20px !important;
  background-color: #7AA095 !important;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  color: #fff;
}
section.react-modal .flexible-modal-drag-area::before{
  content: "<<<";
  padding: 5px 20px;
  font-size: 20px;
}
section.react-modal .flexible-modal-drag-area::after {
  content: ">>>";
  padding: 5px 20px;
  font-size: 20px;
}
section#ann-priceDate{
  position:relative;
}
div#ann-content section p{
  padding:0 40px;
}

/*CHARACTERS COUNTER */
div.d-flex span.input-car-counter{
  font-size: 1.2rem;
  color:blueviolet;
  font-weight: 550;
  margin-left:20px;
}