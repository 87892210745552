/* div .rdrDay{
  pointer-events: none;  
} */
/* div .rdrDateDisplayWrapper{
  padding-top: 25px;} */
div .rdrDateDisplay {
  margin-top:17px;}
.rdrMonths{
  background-color:#DDEBF7;}
/* .rdrMonthAndYearWrapper,.rdrMonthPicker,.rdrYearPicker {
    color: red;
    font-size: 50;
    background-color:#7aa095;} */
.rdrMonthAndYearWrapper{
  background-color:#DDEBF7;
}
div .rdrMonth{
  margin: 0;
  padding-top:0;
  padding-bottom:0;
  z-index: 8;
}

div .rdrMonthName{
  margin: 0;
  padding-top:1;
  padding-bottom:5;
}
#tab3-panel span.rdrDayNumber{
  top:0px;
}