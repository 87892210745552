.swal-modal {
  max-width:65%;
  max-height:50%
}
@media screen and (min-width: 480px){
  .swal-modal {
    max-width: 50%;
  }
}
@media screen and (min-width: 768px) {
  .swal-modal {
    max-width: 35%;
  }
}@media screen and (min-width: 1200px) {
  .swal-modal {
    max-width: 20%;
  }
}
.swal-title {
  font-size: 1.9rem;
  color:rgb(255, 102, 0);
  font-family:monospace

}
.swal-text {
  font-size: 1.7rem;
  color:rgb(26, 25, 25)
}
.swal-button {
  padding-top: 5px;
  padding-bottom: 5px;
}
.swal-button--confirm {
  background: #8CAA7B;
  border: #8CAA7B;
}
.swal-button:hover{
  background: #8CAA7B;
}
.swal-icon {
  width:40px;
  height:40px
}
.swal-icon--warning__body {
  height:20px;
}.swal-icon--warning__dot {
  height:0px;
}