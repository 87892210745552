/*@font-face {
  font-family: Montserrat;
  src: url("https://fonts.googleapis.com/css?family=Montserrat:400,600,700");
}*/
body {
  font-family: Montserrat, Roboto, Roboto, sans-serif !important;
  font-size: 14px !important;
}

a {
  color: #7aa095;
  text-decoration: none !important;
}
a:hover {
  text-decoration: none;
  /* font-weight: 500; */
}

.appbar-icon {
  margin-right: 200px;
}

#render-props-menu {
  min-width: 200px !important;
}

.memberBtn {
  padding: 10px 20px !important;
  border-radius: 5px !important;
  border-color: #28a745 !important;
}

.btn {
  text-transform: capitalize;
}

.btn-success {
  background-color: #7aa095 !important;
  color: #ffffff !important;
  /*padding: 10px 12px;*/
  border-radius: 4px;
  font-size: 14px !important;
  border-color: #ffffff !important;
}

.btn-default {
  outline: 1px solid #e3b62f
}s

.btn-warning {
  background-color: #e3b62f !important;
  color: #ffffff !important;
  padding: 10px 12px;
  border-radius: 4px;
  font-size: 14px !important;
  border-color: #ffffff !important;
}

.btn-trash {
  background-color: #a8170e !important;
  color: #ffffff !important;
  padding: 10px 12px;
  border-radius: 4px;
  font-size: 14px !important;
  border-color: #ffffff !important;
}

.btn-outline-success {
  background-color: transparent !important;
  color: #7aa095 !important;
  padding: 0.375rem 0.75rem;
  border-radius: 4px;
  font-size: 14px !important;
  border-color: #7aa095 !important;
}

.announce-btn {
  margin-bottom: 10px;
}

.paginationCenter {
  margin: auto;
}

.paginationCenter .pagination {
  display: inline-block;
}

.pagination li div {
  margin-top: -30px;
}

.sliderLabel {
  font-size: 1em !important;
  font-weight: 800 !important;
  font-family: Montserrat, "Helvetica", "Arial", sans-serif !important;
  line-height: 1em !important;
  text-align: right !important;
}

.priceRange {
  text-align: center;
}

.datepicker {
  width: 100%;
  margin: 20px auto;
}

