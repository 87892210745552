.gm-style-iw,
button:focus {
  outline: 0;
}
.gm-style-iw{
  font-size: 10px;
  width: 40vw;
  height:40vw;
}
.gm-style-iw img{
  cursor:pointer;
  width:90%;
}

@media (min-width: 768px){
  .gm-style-iw {
      width: 10vw ;
      height: 10vw ;
    }
}
