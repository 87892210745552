div.carousel-wrapper{
  margin: 25px 10px 0;
}
div.carousel-wrapper .containerCarousel {
  position:relative;
  background:
    linear-gradient(rgba(0, 0, 0, 0.6),
      rgba(0, 0, 0, 0.6));
  background-size: cover;
  height:35vw;
  min-height: 220px;
  width:100%;
  border-radius: 5px;
}
div.carousel-wrapper .containerCarousel img{
  object-fit: cover;
  height:35vw;
  min-height: 220px;
  padding:0;
  width:100%;
  border-radius: 5px;
}
div.carousel-wrapper .containerCarousel .carousel-caption{
  position: absolute;
  top:8vw;
  height:fit-content;
  padding:0;
}

div.carousel-wrapper .containerCarousel.active .text {
  display:flex;
  flex-direction:column;
  justify-content:center;
  align-items: center;
  margin:auto;
}
div.carousel-wrapper .containerCarousel .text :is(h2, h3, p){
  color: white;
}

div.carousel-wrapper .containerCarousel .text h2 {
  font-size: 6vw;
}

div.carousel-wrapper .containerCarousel .text h3 {
  font-size: 4vw;
  padding: 1vw 0;
}

div.carousel-wrapper .containerCarousel .text p {
  font-size: 2vw;
  padding: 1vw 0;
  text-align: center;
  line-height: 2vw;
  display:none;
}
div.carousel-wrapper .containerCarousel .text a.btn, 
section.promotionWrapper div.promotion-info a.btn {
  margin:3vw 0 2vw !important;
  font-size: 1.5rem !important;
  width:120px;
  padding:5px !important;
}
@media (min-width: 450px) {
  div.carousel-wrapper .containerCarousel .text h2 {
      font-size: 4vw;
  }  
  div.carousel-wrapper .containerCarousel .text h3 {
    font-size: 3vw;
  }
}
@media (min-width: 768px){
  div.carousel-wrapper .containerCarousel .text h2 {
      font-size: 3vw;
    }
  
    div.carousel-wrapper .containerCarousel .text h3 {
      font-size: 3vw;
    }
  div.carousel-wrapper .containerCarousel .carousel-caption{
    top:5vw
  }
  div.carousel-wrapper {
    margin-top: 35px;
  }
  div.carousel-wrapper .containerCarousel.active .text{
    top:-25vw;
  }
  div.carousel-wrapper .containerCarousel .text p{
    display:block;
  }
  div.carousel-wrapper .containerCarousel .text a.btn,
  section.promotionWrapper div.promotion-info a.btn {
    font-size: 1.4vw !important;
    width:15vw;
  }
}
@media (min-width: 1000px) {
  div.carousel-wrapper .containerCarousel .text h2 {
    font-size: 3vw;
  }
  div.carousel-wrapper .containerCarousel .text h3 {
    font-size: 2vw;
  }
  div.carousel-wrapper .containerCarousel .text p{
    font-size: 1.7vw;
  }
}
@media (min-width: 1400px) {
  div.carousel-wrapper .containerCarousel .text h2 {
    font-size: 2.1vw;
  }
  div.carousel-wrapper .containerCarousel .text h3 {
    font-size: 1.4vw;
  }
  div.carousel-wrapper .containerCarousel .text p {
    font-size: 1.3vw;
  }
  div.carousel-wrapper .containerCarousel.active .text {
    top: -25vw;
  }
  div.carousel-wrapper .containerCarousel .text a.btn,
  section.promotionWrapper div.promotion-info a.btn {
    font-size: 1vw !important;
    width: 10vw;
  }
}

